//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
//
export function findScreenSubjectObj(obj) {
    return request({
        url: '/search/parameters/findScreenSubject',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询错题本列表：
export function queryWrongQuestionObj(obj) {
    return request({
        url: '/study/students/queryWrongQuestion',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 错题详情 ：
export function queryWrongQuestionDetailObj(obj) {
    return request({
        url: `/study/students/queryWrongQuestionDetail/${obj.paperId}/${obj.subjectId}`,    // url = base url + request url
        method: 'get',
    })
}


