<template>
  <div class="case-conent">
    <div class="flex-3">
      <div class="flex-lf"><el-button class="back-btn" @click="back()" icon="el-icon-back" type="primary" size="mini" plain round>返回</el-button></div>
      <div class="flex-center">错题本详情</div>
      <div class="flex-rg"> <el-button class="back-btn" type="primary" size="mini" plain round>共{{errcount}}题</el-button></div>

    </div>
    <!--学历案日志列表-->
    <div class="table-content">
      <div class="wrongQuestionDetails">
        <cace-home-work :homeworks="questionData" :answerSheetData="answerSheetData"></cace-home-work>
      </div>
    </div>
  </div>
</template>


<script>

import {queryWrongQuestionDetailObj} from '@/api/wrongQuestion'
import caceHomeWork from "@/components/caceWrongHomeWork";
export default {
  name: '',
  data() {
    return {
      clickFlag:1,
      SubjectData: [],
      evaluation:{
        subject:''
      },
      errcount:'',
      questionData:[],
      answerSheetData:[],

    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.subjectId = this.$route.query.subjectId;
    this.paperId = this.$route.query.paperId;
    this.listObj()
  },
  watch: {
    // 监视搜索词变化
    $route(){
      this.clickFlag= this.$route.query.clickFlag
    },

  },
  methods: {
    //返回
    back() {
      this.$router.go(-1);
    },


    //查询列表
    listObj() {
      let obj = {
        subjectId: this.subjectId,
        paperId: this.paperId,
      }
      queryWrongQuestionDetailObj(obj).then((res) => { // eslint-disable-line no-unused-vars
        if(res.code==200) {
          this.errcount = res.data.data.errcount
          this.questionData = res.data.data.que[0].question
          this.answerSheetData = res.data.data.que[0].answerSheet
          this.code = res.code;
        } else if(res.code==209) {
          this.code = res.code;
        }
      })
    },
    // 导出错题
    exportWrongQuestion() {

    },
    // 错题详情
    toWrongQuestion() {
      this.$router.push({
        path: "/mainb/timeline",
        query: {
          EducationCase:this.EducationCase,
          clickFlag:1
        }
      });
    },

  },
  components: {
    caceHomeWork
  }
}
</script>
<style lang="scss" scoped>
.case-conent {
  width: 98%;
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;

  .flex-3 {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    font-size: 14px;
    padding: 0 10px;
    box-sizing: border-box;
    margin: auto auto 10px auto;
    display: flex;
    .flex-lf {
      width: 200px;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      .back-btn {
        border: 1px solid #c4e7ff;padding: 0px 20px; height:32px;line-height: 32px; background-color: #37AEFF; color:#c4e7ff
      }
    }
    .flex-center {
      width: 56%;
      flex: 1;
      justify-content: flex-start;
    }
    .flex-rg {
      width: 200px;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      .back-btn {
        border: 1px solid #F77134; justify-content:flex-end; align-items: center; height:32px; width: 80px; background-color: #F77134; color:#FFF
      }
    }
  }
  .hd {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
    position: relative;
    .back {
      position: absolute;
      justify-content: flex-start;
      left: 20px;
    }
  }

  .table-content {
    width: 98%;
    height: 94%;
    margin: 10px auto;
    .wrongQuestionDetails {
      width: 100%;
      height: 95%;
      overflow-y: auto;
      margin: auto;
    }
    .page {
      height: 40px;
      background-color: #238bff;
    }
  }

  .pag-box {
    width: 100%;
    padding-top: 10px;
    box-sizing: content-box;
    justify-content: center;
    display: flex;

    .total-box {
      float: left;
      line-height: 32px;
      margin-right: 10px;
      justify-content: flex-end;
      display: flex;

      .el-pagination {
        white-space: nowrap;
        padding: 2px 5px 2px 0px;
        color: #303133;
        font-weight: 700;

        .el-pager li {
          background: #ccc;
          -webkit-box-sizing: border-box;
        }

        .el-pager li .active {
          background-color: #FF6820;
          color: #FFF;
        }
      }


    }

  }
}
.dialogOrder {
  width: 100%;
  margin: auto auto 20px auto;
  .dialogBody {
    width: 100%;
    line-height: 30px;
    display: flex;
    .lf {
      width: 50%;
      color: #666;
      text-align: right;
    }
    .rg {
      width: 50%;
      color: #000;
      text-align: left;
    }
  }
}
.dialogFooter {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
